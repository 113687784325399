import React from 'react';
import {
  Columns,
  ColumnThird,
  HeaderH2,
  HeaderH3,
  HeadingH4,
  LinkStyle,
  ListItem,
  UnorderedList,
} from '../../styles';
import * as Styled from './contentListWithGreyBackgroundStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Row } from 'react-bootstrap';
import loadable from '@loadable/component';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const GreyCenterDivWithImage = ({ sectionData, location }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(true);
  }, []);
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeaderH2 style={{ textAlign: 'left' }}>{children}</HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeaderH3 style={{ textAlign: 'left' }}>{children}</HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.ParaText>{children}</Styled.ParaText>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <LinkStyle
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </LinkStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.ImageStyle
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return !loaded ? (
    <>
      <Styled.BodyMiddle>
        {sectionData?.header?.trim() && (
          <HeaderH2>{sectionData?.header?.trim()}</HeaderH2>
        )}
        {sectionData?.subtext?.trim() && (
          <HeaderH3 style={{ textAlign: 'center' }}>
            {sectionData?.subtext?.trim()}
          </HeaderH3>
        )}
        {sectionData?.listDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.listDetails?.raw),
            optionsMainStyle
          )}
        {sectionData && (
          <Columns>
            <Row className="g-0">
              {sectionData.list &&
                sectionData.list.map((section, index) => {
                  if (section?.type && sectionList?.includes(section?.type) &&
                  !FormTypes?.includes(section?.type)
                  ) {
                    // const sectionData = sectionData.list[section.type];
                    const SectionComponent = loadable(() =>
                      import(
                        `../../components/${section?.type}/${section?.type}`
                      )
                    );
                    return (
                      <ColumnThird xs={12} lg key={index}>
                        <SectionComponent
                          key={index}
                          sectionData={section}
                          location={location}
                        />
                      </ColumnThird>
                    );
                  }
                })}
            </Row>
          </Columns>
        )}
      </Styled.BodyMiddle>
    </>
  ) : (
    <>
    <Styled.BodyMiddle>
      {sectionData?.header?.trim() && (
        <HeaderH2>{sectionData?.header?.trim()}</HeaderH2>
      )}
      {sectionData?.subtext?.trim() && (
        <HeaderH3 style={{ textAlign: 'center' }}>
          {sectionData?.subtext?.trim()}
        </HeaderH3>
      )}
      {sectionData?.listDetails &&
        documentToReactComponents(
          JSON.parse(sectionData?.listDetails?.raw),
          optionsMainStyle
        )}
      {sectionData && (
        <Columns>
          <Row className="g-0">
            {sectionData.list &&
              sectionData.list.map((section, index) => {
                if (section?.type && sectionList?.includes(section?.type)) {
                  // const sectionData = sectionData.list[section.type];
                  const SectionComponent = loadable(() =>
                    import(
                      `../../components/${section?.type}/${section?.type}`
                    )
                  );
                  return (
                    <ColumnThird xs={12} lg key={index}>
                      <SectionComponent
                        key={index}
                        sectionData={section}
                        location={location}
                      />
                    </ColumnThird>
                  );
                }
              })}
          </Row>
        </Columns>
      )}
    </Styled.BodyMiddle>
  </>
  );
};

export default GreyCenterDivWithImage;
